import React, { useEffect, useState } from "react";

import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { SiteService, Tour, AdminService } from "@genezio-sdk/westtigermotors";
import { Faq } from "../../components/Faq";
import { Testimonials } from "../../components/Testimonials";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";
import dayjs from "dayjs";
import { OurTours } from "../pages/OurTours";
import { useTranslation } from "react-i18next";
import { Accordion, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { articleStructuredData } from "../../components/Auth/utils";
import { BrowserView, MobileView } from "react-device-detect";

const Home = () => {
  const { t, i18n } = useTranslation();

  const [date, setDate] = useState<Date | null>(null);

  const [tours, setTours] = useState<Tour[]>([]);
  const [selectedTourId, setSelectedTourId] = useState<string | undefined>(
    undefined
  );

  const [startLocation, setStartLocation] = useState<string | undefined>(
    undefined
  );

  const [soldOutDates, setSoldOutDates] = useState<Date[]>([]);

  const [availableDates, setAvailableDates] = useState<Date[]>([]);
  const [availableDatesDays, setAvailableDatesDays] = useState<number[]>([]);
  const [availableDatesColors, setAvailableDatesColors] = useState<string[]>(
    []
  );

  const locations = [
    { value: "Bucharest", label: t("home.search.locations.bucharest") },
    { value: "Brasov", label: t("home.search.locations.brasov") },
    { value: "Training", label: t("home.search.locations.training") }
  ];
  // const [hovered, setHovered] = useState(false);

  useEffect(() => {
    async function getAvailableDates() {
      if (!startLocation) {
        // get all available dates from tours
        const dates = tours
          .map((tour) => tour.tourDates)
          .flat()
          .map((date) => new Date(date));
        setAvailableDates(dates);
        return;
      }

      const resp = await SiteService.getAvailableDatesByStartLocation(
        startLocation || "",
        selectedTourId
      ).catch((err) => {
        console.error(err);
        return null;
      });
      if (!resp) {
        return;
      }
      if (startLocation === "Training") {
        setSelectedTourId(process.env.REACT_APP_TRAINING_ID);
      }
      setAvailableDates(resp.availableDates.map((date) => new Date(date)));
      setAvailableDatesDays(resp.days);
      setAvailableDatesColors(resp.colors);
      setSoldOutDates(resp.soldOutDates.map((date) => new Date(date)));
    }

    getAvailableDates();
  }, [startLocation, selectedTourId]);

  useEffect(() => {
    async function getTours() {
      const tours = await AdminService.getTours().catch((err) => {
        console.error(err);
        return [];
      });
      setTours(tours);
    }

    getTours();
  }, []);

  async function search() {
    if (!date) {
      alert(t("home.search.searchNoDateAlert"));
      return;
    }

    const tour = await SiteService.getTourByDateAndStartLocation(
      startLocation || "",
      date.getTime()
    ).catch((err) => {
      console.error(err);
      return null;
    });

    if (!tour) {
      alert(t("home.search.alertNoTours"));
      return;
    }

    if (tour.startLocation === "Training") {
      window.location.href = `/training/${tour.id}/${date.getTime()}`;
      return;
    }

    window.location.href = `/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/${date.getTime()}/`;
  }

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const rentalslideroption = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,

    // allow exactly 3 slides to show on screen
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4000
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 4000
        }
      }
    ]
  };
  return (
    <>
      {localStorage.getItem("i18nextLng") === "ro" ? (
        <Helmet>
          <link rel="canonical" href="https://firstgearclub.com" />

          <link
            rel="alternate"
            hrefLang="ro"
            href="https://firstgearclub.com"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href="https://firstgearclub.com?lang=en"
          />
          <title>
            Experienta off-road Romania - Trasee 4x4 cu ghid - First Gear Club
          </title>
          <meta
            name="description"
            content="Descopera experienta off road impreuna cu ghizii nostriexperimentati. Trasee montane, masini 4x4 performante ⭐ Aventura garantata."
          />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="canonical" href="https://firstgearclub.com" />

          <link
            rel="alternate"
            hrefLang="ro"
            href="https://firstgearclub.com"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href="https://firstgearclub.com?lang=en"
          />
          <title>
            Off-Road adventure tours - Romanian 4x4 experience - First Gear Club
          </title>
          <meta
            name="description"
            content="Discover the off road experience together with our experienced guides. Mountain trails, high-performance 4x4 cars ⭐ Adventure guaranteed."
          />
        </Helmet>
      )}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData)
        }}
      />
      {/* Banner */}
      <section className="banner-section banner-slider">
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center">
              <div className="col-lg-7">
                {/* <p className="explore-text">
                  {" "}
                  <span>
                    <i className="fa-solid fa-thumbs-up me-2"></i>
                  </span>
                  {t("home.hero.pretext")}
                </p> */}
                <h1 className="primaryFont" style={{ color: "white" }}>
                  <span>{t("home.hero.titleRow1")}</span> <br />
                  {t("home.hero.titleRow2")}
                </h1>
                <p>{t("home.hero.text")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Banner */}
      {/* Search */}

      <section className="section popular-services pt-4 pb-1">
        <div className="container">
          {/* Heading title*/}
          {/* <div className="section-heading" data-aos="fade-down">
            <h2 className="primaryFont">{t("home.promoAds.title")}</h2>
          </div> */}
          {/* /Heading title */}
          <div className="row">
            <div className="popular-slider-group">
              <div className=" rental-deal-slider ">
                <Slider {...rentalslideroption} className="rental-slider">
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a href="/tururile-noastre">
                          <BrowserView>
                            <img
                              src={`assets/img/ad_BF_${i18n.language === "ro" ? "ro" : "eng"}_web.jpg`}
                              className="img-fluid"
                              alt="banner-black-friday"
                              loading="lazy"
                            />
                          </BrowserView>
                          <MobileView>
                            <img
                              src={`assets/img/ad_BF_${i18n.language === "ro" ? "ro" : "eng"}_tel.jpg`}
                              className="img-fluid"
                              alt="banner-black-friday"
                              loading="lazy"
                            />
                          </MobileView>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a href="/tur/Baiului-Trails-668d91aaa5007a3259499e24/">
                          <BrowserView>
                            <img
                              src={`assets/img/banner_baiului_${i18n.language === "ro" ? "ro" : "eng"}_web.jpg`}
                              className="img-fluid"
                              alt="banner-black-friday"
                              loading="lazy"
                            />
                          </BrowserView>
                          <MobileView>
                            <img
                              src={`assets/img/banner_baiului_${i18n.language === "ro" ? "ro" : "eng"}_tel.jpg`}
                              className="img-fluid"
                              alt="banner-black-friday"
                              loading="lazy"
                            />
                          </MobileView>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setStartLocation("Brasov");
                            setSelectedTourId(undefined);
                            // scroll to top smoothly
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth"
                            });
                            // setHovered(true);
                          }}
                        >
                          <img
                            src={`assets/img/banner1${i18n.language === "ro" ? "_ro" : ""}.jpg`}
                            className="img-fluid"
                            alt="banner-1"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a href="/partener-de-calatorie">
                          <img
                            src={`assets/img/banner2${i18n.language === "ro" ? "_ro" : ""}.jpg`}
                            className="img-fluid"
                            alt="banner-2"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            // add ?popup=signup to the url
                            window.location.href = "?popup=signup";
                          }}
                        >
                          <img
                            src={`assets/img/banner3${i18n.language === "ro" ? "_ro" : ""}.jpg`}
                            className="img-fluid"
                            alt="banner-3"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a
                          href={`/training/${process.env.REACT_APP_TRAINING_ID}`}
                        >
                          <img
                            src={`assets/img/banner4${i18n.language === "ro" ? "_ro" : ""}.jpg`}
                            className="img-fluid"
                            alt="banner-4"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="listing-item mb-0">
                      <div className="listing-img">
                        <a href={`/experienta-off-road-voucher-cadou`}>
                          <img
                            src={`assets/img/banner5${i18n.language === "ro" ? "_ro" : ""}.jpg`}
                            className="img-fluid"
                            alt="banner-5"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section-search">
        <div className="container">
          <div className="search-box-banner">
            <Row className="align-items-start justify-content-evenly">
              <div className="mt-2 col-12 col-md-5">
                <div className="delivery-tab">
                  <div className="input-block p-0">
                    <label className="label-custom-search-home">
                      {t("home.search.startLocation")}
                    </label>
                    <Accordion activeKey={startLocation || undefined}>
                      {locations.map((location) => (
                        <Accordion.Item
                          eventKey={location.value}
                          key={location.value}
                        >
                          <Accordion.Header
                            onClick={() => {
                              setSelectedTourId(undefined);
                              if (startLocation === location.value) {
                                setStartLocation(undefined);
                                return;
                              }
                              setStartLocation(location.value);
                            }}
                          >
                            {location.label}
                          </Accordion.Header>
                          <Accordion.Body>
                            {tours
                              .filter(
                                (tour) => tour.startLocation === location.value
                              )
                              .map((tour) => (
                                <label
                                  className="booking_custom_check"
                                  key={tour.id}
                                  onClick={() => {
                                    if (tour.tourDates.length === 0) {
                                      window.location.href = `/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`;
                                    }
                                    setSelectedTourId(tour.id || undefined);
                                  }}
                                >
                                  <input
                                    type="radio"
                                    name="tourName"
                                    style={{ display: "none" }}
                                    onClick={() => {
                                      setSelectedTourId(tour.id || undefined);
                                    }}
                                    checked={selectedTourId === tour.id}
                                  />
                                  <div className="booking_checkmark d-flex flex-column align-items-start p-3">
                                    <div className="d-flex flex-row justify-content-between w-100 align-items-center">
                                      <div className="checked-title tour-home-span-title">
                                        <span>
                                          {localStorage.getItem(
                                            "i18nextLng"
                                          ) === "ro"
                                            ? tour.nameRo
                                            : tour.name}
                                          {" - "}
                                          {tour.numberOfDays}{" "}
                                          {tour.numberOfDays === 1
                                            ? t(
                                                "home.tourSection.numberOfDaysLabel_one"
                                              )
                                            : t(
                                                "home.tourSection.numberOfDaysLabel_other"
                                              )}
                                        </span>
                                      </div>

                                      {selectedTourId === tour.id && (
                                        <a
                                          className="btn btn-outline-primary btn-sm tour-home-span-title h-50"
                                          href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`}
                                        >
                                          {t("home.search.viewDetails")}
                                        </a>
                                      )}
                                    </div>
                                    {tour.tourDates.length === 0 && (
                                      <div className="mt-2">
                                        <span className="text-danger">
                                          {t("home.search.noDates")}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </label>
                              ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>

              <div className="mt-2 col-12 col-md-5">
                <div className="input-block p-0">
                  {!startLocation ? (
                    <label className="label-custom-search-home">
                      {t("home.search.selectDateActivities")}
                    </label>
                  ) : (
                    <label className="label-custom-search-home">
                      {t("home.search.selectDate")}
                    </label>
                  )}
                </div>
                <div className="input-block-wrapp">
                  <div className="input-block date-widget w-100 p-0">
                    <div className="group-img w-100">
                      <div
                        className="calendar-container w-100"
                        // onMouseEnter={() => setHovered(true)}
                        // onMouseLeave={() => setHovered(false)}
                      >
                        {/* {hovered && (
                          <div className="calendar-popup">
                            <button
                              type="button"
                              className="btn-close float-end"
                              aria-label="Close"
                              onClick={() => setHovered(false)}
                            ></button> */}

                        <Calendar
                          value={date}
                          onChange={(e) => {
                            const localDate = e as Date;
                            if (!localDate) {
                              setDate(null);
                              return;
                            }

                            if (!startLocation) {
                              window.location.href = `/tururile-noastre?date=${new Date(
                                Date.UTC(
                                  localDate.getFullYear(),
                                  localDate.getMonth(),
                                  localDate.getDate()
                                )
                              )
                                .getTime()
                                .toString()}`;
                              return;
                            }

                            const finishDates = availableDates.map((d, index) =>
                              dayjs(d).add(availableDatesDays[index] - 1, "day")
                            );

                            if (
                              !availableDates.find(
                                (d) =>
                                  // check yyyy-mm-dd
                                  dayjs(d).format("YYYY-MM-DD") ===
                                  dayjs(localDate).format("YYYY-MM-DD")
                              ) &&
                              !availableDates.find((d, index) => {
                                const finishDate = finishDates[index];
                                return (
                                  dayjs(localDate).format("YYYY-MM-DD") >
                                    dayjs(d).format("YYYY-MM-DD") &&
                                  dayjs(localDate).format("YYYY-MM-DD") <=
                                    dayjs(finishDate).format("YYYY-MM-DD")
                                );
                              })
                            ) {
                              return;
                            }

                            const foundDate = availableDates.find(
                              (d, index) => {
                                const finishDate = finishDates[index];
                                return (
                                  dayjs(localDate).format("YYYY-MM-DD") >
                                    dayjs(d).format("YYYY-MM-DD") &&
                                  dayjs(localDate).format("YYYY-MM-DD") <=
                                    dayjs(finishDate).format("YYYY-MM-DD")
                                );
                              }
                            );

                            if (foundDate) {
                              setDate(
                                new Date(
                                  Date.UTC(
                                    foundDate.getFullYear(),
                                    foundDate.getMonth(),
                                    foundDate.getDate()
                                  )
                                )
                              );
                              return;
                            }

                            setDate(
                              new Date(
                                Date.UTC(
                                  localDate.getFullYear(),
                                  localDate.getMonth(),
                                  localDate.getDate()
                                )
                              )
                            );
                          }}
                          minDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 1)
                            )
                          }
                          className="w-100"
                          tileClassName={(elems) => {
                            const localDate = elems.date as Date;
                            const view = elems.view;
                            if (view !== "month") {
                              return "";
                            }

                            if (!startLocation) {
                              return "";
                            }

                            const indexOfSelected = availableDates.findIndex(
                              (d) =>
                                dayjs(d).format("YYYY-MM-DD") ===
                                dayjs(date).format("YYYY-MM-DD")
                            );
                            if (
                              indexOfSelected >= 0 &&
                              dayjs(availableDates[indexOfSelected]).format(
                                "YYYY-MM-DD"
                              ) === dayjs(localDate).format("YYYY-MM-DD") &&
                              availableDatesDays[indexOfSelected] === 1
                            ) {
                              return "active-date";
                            }

                            if (
                              indexOfSelected >= 0 &&
                              dayjs(availableDates[indexOfSelected]).format(
                                "YYYY-MM-DD"
                              ) === dayjs(localDate).format("YYYY-MM-DD") &&
                              availableDatesDays[indexOfSelected] > 1
                            ) {
                              return "active-date-start";
                            }

                            if (indexOfSelected >= 0) {
                              const finishDate = dayjs(
                                availableDates[indexOfSelected]
                              ).add(
                                availableDatesDays[indexOfSelected] - 1,
                                "day"
                              );
                              if (
                                dayjs(localDate).format("YYYY-MM-DD") ===
                                finishDate.format("YYYY-MM-DD")
                              ) {
                                return "active-date-end";
                              }

                              if (
                                dayjs(localDate).format("YYYY-MM-DD") >
                                  dayjs(availableDates[indexOfSelected]).format(
                                    "YYYY-MM-DD"
                                  ) &&
                                dayjs(localDate).format("YYYY-MM-DD") <
                                  finishDate.format("YYYY-MM-DD")
                              ) {
                                return "active-date-middle";
                              }
                            }

                            const indexAvailable = availableDates
                              .map((d) => dayjs(d).format("YYYY-MM-DD"))
                              .indexOf(dayjs(localDate).format("YYYY-MM-DD"));

                            let color = "disabled-date";
                            if (indexAvailable >= 0) {
                              color = availableDatesColors[indexAvailable];
                              // create a class called highlight-{color} and add it to css
                              // to style the dates with the color
                              const style = document.createElement("style");
                              style.type = "text/css";
                              style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                              document
                                .getElementsByTagName("head")[0]
                                .appendChild(style);
                            }

                            if (
                              indexAvailable >= 0 &&
                              availableDatesDays[indexAvailable] === 1
                            ) {
                              return `highlight-date highlight-${color}`;
                            }

                            if (
                              indexAvailable >= 0 &&
                              availableDatesDays[indexAvailable] > 1
                            ) {
                              return `highlight-date-start highlight-${color}`;
                            }

                            const finishDates = availableDates.map((d, index) =>
                              dayjs(d).add(availableDatesDays[index] - 1, "day")
                            );

                            const indexFinish = finishDates
                              .map((d) => dayjs(d).format("YYYY-MM-DD"))
                              .indexOf(dayjs(localDate).format("YYYY-MM-DD"));

                            color = availableDatesColors[indexFinish] || color;
                            // create a class called highlight-{color} and add it to css
                            // to style the dates with the color
                            const style = document.createElement("style");
                            style.type = "text/css";
                            style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                            document
                              .getElementsByTagName("head")[0]
                              .appendChild(style);

                            if (indexFinish >= 0) {
                              return `highlight-date-end highlight-${color}`;
                            }

                            const filterDate = availableDates.filter(
                              (d, index) =>
                                dayjs(d).format("YYYY-MM-DD") <
                                  dayjs(localDate).format("YYYY-MM-DD") &&
                                dayjs(localDate).format("YYYY-MM-DD") <
                                  dayjs(finishDates[index]).format("YYYY-MM-DD")
                            );

                            if (filterDate.length > 0) {
                              color =
                                availableDatesColors[
                                  availableDates.indexOf(filterDate[0])
                                ] || color;
                              // create a class called highlight-{color} and add it to css
                              // to style the dates with the color
                              const style = document.createElement("style");
                              style.type = "text/css";
                              style.innerHTML = `.highlight-${color} { background-color: #${color}; }`;
                              document
                                .getElementsByTagName("head")[0]
                                .appendChild(style);
                              return `highlight-date-middle highlight-${color}`;
                            }

                            if (
                              soldOutDates.find(
                                (d) =>
                                  dayjs(d).format("YYYY-MM-DD") ===
                                  dayjs(localDate).format("YYYY-MM-DD")
                              )
                            ) {
                              return "calendar-element-sold";
                            }

                            return "disabled-date";
                          }}
                        />
                        <div className="mt-3">
                          {tours
                            .filter(
                              (tour) => tour.startLocation === startLocation
                            )
                            .filter((tour) => tour.tourDates.length > 0)
                            .map((tour) => (
                              <div
                                key={tour.id}
                                className="d-flex flex-row align-items-center mb-2"
                              >
                                <div
                                  className="home-color-custom-bubble me-2"
                                  style={{
                                    backgroundColor:
                                      `#${tour.color}` || "#2e3840"
                                  }}
                                ></div>
                                {tour.name}
                              </div>
                            ))}
                        </div>
                        {/* </div> 
                        )}
                        <div className="hover-target w-100">
                          {date?.toDateString() ||
                            t("home.search.openCalendar")}
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 column-group-last p-1">
                <div className="input-block">
                  <div className="search-btn">
                    <button
                      className="btn search-button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!date) {
                          alert(t("home.search.searchNoDateAlert"));
                        }
                        search();
                      }}
                    >
                      <i className="fa fa-search" aria-hidden="true" />
                      {t("home.search.searchLabel")}
                    </button>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
      {/* /Search */}

      <OurTours isHomePage />

      {/* services */}
      <section className="section services" style={{ background: "#F2F7F6" }}>
        <div className="service-right">
          <ImageWithBasePath
            src="assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="primaryFont">{t("home.howItWorksSection.title")}</h2>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12 d-flex">
                <div className="services-group service-date flex-fill">
                  <div className="mb-4">
                    <img
                      src="assets/img/photo1.jpg"
                      alt=""
                      height={180}
                      className="img-fluid rounded-4"
                      loading="lazy"
                    />
                  </div>
                  <div className="services-content">
                    <h3>{t("home.howItWorksSection.step1.title")}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("home.howItWorksSection.step1.text")
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12  d-flex">
                <div className="services-group service-loc flex-fill">
                  <div className="mb-4">
                    <img
                      src="assets/img/photo2.jpg"
                      alt=""
                      height={180}
                      className="img-fluid rounded-4"
                      loading="lazy"
                    />
                  </div>
                  <div className="services-content">
                    <h3>{t("home.howItWorksSection.step2.title")}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("home.howItWorksSection.step2.text")
                      }}
                    />
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 d-flex">
                <div className="services-group service-book flex-fill">
                  <div className="mb-4">
                    <img
                      src="assets/img/photo3.jpg"
                      alt=""
                      height={180}
                      className="img-fluid rounded-4"
                      loading="lazy"
                    />
                  </div>
                  <div className="services-content">
                    <h3>{t("home.howItWorksSection.step3.title")}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("home.howItWorksSection.step3.text")
                      }}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}

      {/* Facts By The Numbers */}
      <section className="section facts-number">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="title text-white primaryFont">
              {t("home.factsSection.title")}
            </h2>
            {/* <p className="description text-white">
              {t("home.factsSection.subtitle")}
            </p> */}
          </div>
          {/* /Heading title */}
          <div className="counter-group">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-heart.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={1000}
                          duration={3}
                          separator=","
                        />
                        +<br />
                      </h4>
                      <p>{t("home.factsSection.fact1.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-car.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={5}
                          duration={2}
                          separator=","
                        />
                        +<br />
                      </h4>
                      <p>{t("home.factsSection.fact2.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-headphone.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={625}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>{t("home.factsSection.fact3.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 col-12 d-flex"
                data-aos="fade-down"
              >
                <div className="count-group flex-fill">
                  <div className="customer-count d-flex align-items-center">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-history.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={200}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>{t("home.factsSection.fact4.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Facts By The Numbers */}

      {/* Why Choose Us */}
      <section className="section why-choose popular-explore">
        <div className="choose-left">
          <ImageWithBasePath
            src="assets/img/bg/choose-left.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="primaryFont">{t("home.whyUsSection.title")}</h2>
            {/* <p>{t("home.whyUsSection.subtitle")}</p> */}
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-black">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-selection.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>{t("home.whyUsSection.reason1.title")}</h4>
                      <p>{t("home.whyUsSection.reason1.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-crown.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>{t("home.whyUsSection.reason2.title")}</h4>
                      <p>{t("home.whyUsSection.reason2.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>{t("home.whyUsSection.reason3.title")}</h4>
                      <p>{t("home.whyUsSection.reason3.text")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      <Testimonials />
      {/* About us Testimonials */}
      {/* FAQ  */}
      <Faq isHomePage={true} />
      {/* /FAQ */}
    </>
  );
};

export default Home;
