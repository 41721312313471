/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useNavigate, useParams } from "react-router-dom";
import {
  SiteService,
  Tour,
  AdminService,
  Package,
  Extra,
  Car,
  Rider
} from "@genezio-sdk/westtigermotors";
import dayjs from "dayjs";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { countryPhonePrefixes } from "../../core/data/json/countries";
import Select from "react-select";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../components/Breadcrumbs";

const Checkout = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { tourId, date, packageName } = useParams<{
    tourId: string;
    date: string;
    packageName: string;
  }>();

  const [activeStep, setActiveStep] = useState("add-ons");

  const [tour, setTour] = useState<Tour | null>(null);
  const [packageObject, setPackageObject] = useState<Package | null>(null);

  const selectedDate = date ? new Date(parseInt(date)) : null;

  const [extras, setExtras] = useState<Extra[]>([]);
  const [selectedVoucherPercentage, setSelectedVoucherPercentage] = useState<
    number | null
  >(null);
  const [voucherCode, setVoucherCode] = useState("");
  const [isCheckingVoucher, setIsCheckingVoucher] = useState(false);

  const [showModalCar, setShowModalCar] = useState(false);
  const handleClose = () => {
    setActiveCarIndex(-1);

    const localCarColorIds = [...carColorIds];
    localCarColorIds[activeCarIndex] = "";
    setCarColorIds(localCarColorIds);
    setShowModalCar(false);
  };
  const handleShow = () => setShowModalCar(true);

  const [availableCars, setAvailableCars] = useState<Car[]>([]);
  const [cars, setCars] = useState<Car[]>([]);

  const [carColorIds, setCarColorIds] = useState<string[]>([]);

  const [driversInfo, setDriversInfo] = useState<Rider[]>([]);
  const [comments, setComments] = useState("");
  const [travelCompanionNotes, setTravelCompanionNotes] = useState("");

  const [agreeTerms, setAgreeTerms] = useState(false);

  const [isCreatingBooking, setIsCreatingBooking] = useState(false);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [carsExtras, setCarsExtras] = useState<string[][]>([]);
  const [activeCarIndex, setActiveCarIndex] = useState(-1);

  useEffect(() => {
    // set title of the page
    document.title = "First Gear Club | Checkout";
  }, []);

  useEffect(() => {
    async function asyncCall() {
      const res = await Promise.all([
        AdminService.getTourById(tourId || ""),
        SiteService.getTourAvailableDates(tourId || ""),
        AdminService.getAllExtras(),
        SiteService.getAvailableCarsByTourAndDate(
          tourId || "",
          selectedDate?.getTime() || 0
        )
      ]);

      if (localStorage.getItem("token")) {
        setVoucherCode("CLUBUSER5");
        applyVoucher("CLUBUSER5");
      }

      setTour(res[0]);

      const index = res[1].availableDates.findIndex((date) => {
        // check only the date part
        return (
          dayjs(date).format("YYYY-MM-DD") ===
          dayjs(selectedDate).format("YYYY-MM-DD")
        );
      });

      if (index === -1) {
        navigate("/");
        return;
      }

      const packageIndex = res[0].packages.findIndex(
        (p) => p.name === packageName
      );

      if (packageIndex === -1) {
        navigate("/");
        return;
      }

      setPackageObject(res[0].packages[packageIndex]);

      setCarsExtras(
        Array.from({
          length:
            res[0].packages[packageIndex].vehicles > 1
              ? res[0].packages[packageIndex].vehicles
              : 1
        }).map(() => [])
      );

      if (
        res[0].packages[packageIndex].vehicles > res[1].seatsAvailable[index]
      ) {
        navigate("/");
        return;
      }

      setExtras(res[2]);
      setAvailableCars(res[3]?.availableCars || []);
      setCars(res[3]?.cars || []);

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ items: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "view_cart",
        currency: "EUR",
        value: res[0].packages[packageIndex].price,
        items: [
          {
            item_id: res[0].id,
            item_name: res[0].name,
            item_category: res[0].startLocation,
            item_variant: packageName,
            price: res[0].packages[packageIndex].price,
            quantity: 1,
            index: 0
          }
        ]
      });
    }

    asyncCall();
  }, []);

  function getTourPrice() {
    if (!tour || !packageObject || !selectedDate || !extras) {
      return 0;
    }

    let extrasPrice = 0;

    const selectedExtras = carsExtras.flat();

    for (const extraId of selectedExtras) {
      const extra = extras.find((e) => e.id === extraId);
      if (extra) {
        extrasPrice += extra.price * (extra.perDay ? tour.numberOfDays : 1);
      }
    }

    return (packageObject?.price || 0) + extrasPrice;
  }

  async function applyVoucher(voucher?: string) {
    if (!voucherCode && !voucher) {
      alert(t("checkout.alerts.enterVoucherCodeAlert"));

      return;
    }

    // check if logged in
    if (!localStorage.getItem("token")) {
      alert(t("checkout.alerts.enterVoucherLoginAlert"));
      // add to link state ?popup=signup
      window.history.pushState({}, "", "?popup=signup");
      // refresh the page
      window.location.reload();
      return;
    }

    let localVoucher = voucherCode;

    if (voucher) {
      localVoucher = voucher;
    }

    setIsCheckingVoucher(true);
    const res = await AdminService.getDiscountPercentageByVoucherCode(
      localVoucher || "",
      getTourPrice()
    ).catch((e) => {
      console.error(e);
      alert(e.message);
      setVoucherCode("");

      return null;
    });
    setIsCheckingVoucher(false);

    if (res === null) {
      alert(t("checkout.alerts.invalidVoucherCodeAlert"));
      return;
    }

    setSelectedVoucherPercentage(res);
  }

  async function createBooking() {
    if (!tour || !packageObject || !selectedDate || !extras) {
      return;
    }

    if (voucherCode === "CLUBUSER5" && !localStorage.getItem("token")) {
      alert(t("checkout.alerts.enterVoucherLoginAlert"));
      return;
    }

    if (
      driversInfo.some(
        (driver) =>
          !driver.firstName ||
          !driver.lastName ||
          !driver.email ||
          !driver.phone ||
          !driver.address ||
          !driver.country
      )
    ) {
      alert(t("checkout.alerts.fillAllFieldsAlert"));
      return;
    }

    if (!agreeTerms) {
      alert(t("checkout.alerts.agreeToTermsAlert"));
      return;
    }

    const extrasRaw = carsExtras.flat();

    const extrasArray = extrasRaw.reduce(
      (acc, curr) => {
        const found = acc.find((extra) => extra.id === curr);
        if (found) {
          found.quantity++;
        } else {
          acc.push({ id: curr, quantity: 1 });
        }
        return acc;
      },
      [] as { id: string; quantity: number }[]
    );

    setIsCreatingBooking(true);
    const res = await SiteService.createNewBooking({
      tourId: tour.id || "",
      date: selectedDate.getTime(),
      packageName: packageObject.name,
      extras: extrasArray,
      carColorIds: carColorIds,
      ridersInfo: driversInfo,
      comments,
      travelCompanionNotes,
      voucherCode: voucherCode
    }).catch((e) => {
      console.error(e);
      return null;
    });
    setIsCreatingBooking(false);

    if (res === null) {
      alert(t("checkout.alerts.tryAgainAlert"));
      return;
    }

    window.location.href = res;
  }

  return (
    <div>
      <Modal show={showModalCar} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("checkout.carForDriverLabel")} {activeCarIndex + 1}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("checkout.carForClientLabel")}
          <Row className="d-flex flex-column flex-md-row justify-content-evenly align-content-center">
            {cars.map((car) => (
              <Col
                md={3}
                key={car.id}
                className={`m-2 p-2 car-info-wrapper ${
                  availableCars.find((c) => c.id === car.id)
                    ? ""
                    : "car-info-wrapper-disabled"
                }
                ${carColorIds.find((c) => c === car.id) && carColorIds[activeCarIndex] !== car.id ? "car-info-wrapper-disabled" : ""}
                 ${carColorIds[activeCarIndex] === car.id ? "car-info-wrapper-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (!availableCars.find((c) => c.id === car.id)) {
                    return;
                  }

                  if (
                    carColorIds.find((c) => c === car.id) &&
                    carColorIds[activeCarIndex] !== car.id
                  ) {
                    return;
                  }

                  const localCarColorIds = [...carColorIds];

                  localCarColorIds[activeCarIndex] = car.id || "";
                  setCarColorIds(localCarColorIds);
                }}
              >
                <div className="car-card">
                  <div className="car-img">
                    <img
                      src={`${process.env.REACT_APP_STORAGE_BASE_URL}/cars/${car.id}/${car.photos[0]}`}
                      className="img-fluid"
                      alt="Car"
                    />
                  </div>
                  <div className="car-info text-center">
                    <h5 className="mt-2">{car.name}</h5>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary remove-adon-btn"
            disabled={!carColorIds[activeCarIndex]}
            onClick={(e) => {
              e.preventDefault();
              const localCarsExtras = [...carsExtras];
              localCarsExtras[activeCarIndex] = [
                ...localCarsExtras[activeCarIndex],
                extras.find((e) => e.extraColor)?.id || ""
              ];
              setCarsExtras(localCarsExtras);
              setShowModalCar(false);

              // @ts-ignore
              window.dataLayer =
                // @ts-ignore
                window.dataLayer || [];
              // @ts-ignore
              window.dataLayer.push({
                items: null
              });
              // @ts-ignore
              window.dataLayer.push({
                event: "add_to_cart",
                currency: "EUR",
                value: extras.find((e) => e.extraColor)?.price,
                items: [
                  {
                    item_id: extras.find((e) => e.extraColor)?.id || "",
                    item_name: extras.find((e) => e.extraColor)?.name,
                    price: extras.find((e) => e.extraColor)?.price,
                    quantity: 1,
                    index: 0,
                    item_xsell: tour?.name
                  }
                ]
              });
            }}
          >
            <i className="bx bx-plus-circle me-2" />
            {t("checkout.addLabel")}
          </button>
          <Button
            variant=""
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            {t("checkout.closeLabel")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Breadcrumbs
        items={[
          {
            link: `/tur/${tour?.name.replaceAll(" ", "-")}-${tour?.id}/`,
            name: i18n.language === "ro" ? tour?.nameRo || "" : tour?.name || ""
          },
          {
            link: `#`,
            name: "Checkout"
          }
        ]}
        title={`Checkout`}
      />
      <div className="pt-4">
        <div className="container">
          <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <h4> {t("checkout.reserveTourLabel")}</h4>
                  <p>{t("checkout.completeStepsLabel")}</p>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li
                      className={`${["add-ons", "details", "checkout"].includes(activeStep) ? "active" : ""}`}
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-02.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.addonsLabel")}</h6>
                    </li>
                    <li
                      className={`${["details", "checkout"].includes(activeStep) ? "active" : ""}`}
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-03.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.detailsLabel")}</h6>
                    </li>
                    <li
                      className={`${activeStep === "checkout" ? "active" : ""}`}
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-04.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.checkoutLabel")}</h6>
                    </li>
                    <li
                      className={`${activeStep === "confirmed" ? "active" : ""}`}
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-05.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.bookingConfirmedLabel")}</h6>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="booking-detail-info">
            <div className="row">
              <div className="col-lg-4 theiaStickySidebar">
                <div className="stickybar">
                  <div className="booking-sidebar">
                    <div className="booking-sidebar-card">
                      <div className="booking-sidebar-head">
                        <h5>{t("checkout.tourDetailsLabel")}</h5>
                      </div>
                      <div className="booking-sidebar-body">
                        <div className="booking-car-detail">
                          <span className="car-img">
                            <img
                              src={`${process.env.REACT_APP_STORAGE_BASE_URL}/tours/${tour?.id}/${tour?.photos[0]}`}
                              className="img-fluid"
                              alt="Car"
                            />
                          </span>
                          <div className="care-more-info">
                            <h5>
                              {i18n.language === "ro"
                                ? tour?.nameRo
                                : tour?.name}
                            </h5>
                            <p className="mb-0">
                              <b>
                                {" "}
                                {tour?.numberOfDays === 1
                                  ? "Date:"
                                  : "Start Date:"}
                              </b>{" "}
                              {dayjs(selectedDate).format("DD MMMM YYYY")}
                            </p>
                            <p>
                              <b> {t("checkout.durationLabel")} </b>
                              {tour?.numberOfDays === 1
                                ? "1 Day"
                                : `${tour?.numberOfDays} Days`}
                            </p>
                            <a
                              href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour?.id}/`}
                              className="view-tour-btn"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("checkout.viewDetailsLabel")}
                            </a>
                          </div>
                        </div>
                        <div className="booking-vehicle-rates">
                          <ul>
                            <li>
                              <div className="rental-charge">
                                <h6>{packageObject?.name}</h6>
                                <div className="d-flex flex-row justify-content-between w-100">
                                  <div className="d-flex align-items-center">
                                    <ImageWithBasePath
                                      src="assets/img/specification/specification-icon-6.svg"
                                      alt="Icon"
                                      height={15}
                                      className="me-1"
                                    />
                                    {tour?.startLocation !== "Training" ? (
                                      <>
                                        {packageObject?.rides == 1 &&
                                        packageObject?.vehicles == 1 ? (
                                          <span>
                                            {t(
                                              "tourPage.driver1nondriverLabel"
                                            )}
                                          </span>
                                        ) : packageObject?.rides == 2 &&
                                          packageObject?.vehicles == 2 ? (
                                          <span>
                                            {t(
                                              "tourPage.driver2nondriverLabel"
                                            )}
                                          </span>
                                        ) : packageObject?.rides == 4 &&
                                          packageObject?.vehicles == 2 ? (
                                          <span>
                                            4 {t("tourPage.driverOtherLabel")}
                                          </span>
                                        ) : packageObject?.rides == 1 &&
                                          packageObject?.vehicles == 2.5 ? (
                                          <span>
                                            1 {t("tourPage.driverOneLabel")}
                                          </span>
                                        ) : (
                                          <span>
                                            {packageObject?.rides}{" "}
                                            {t("tourPage.driverOneLabel")}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      <span> {t("tourPage.driver1Label")}</span>
                                    )}
                                  </div>
                                  <div className="d-flex align-items-center ms-3">
                                    <ImageWithBasePath
                                      src="assets/img/specification/specification-icon-1.svg"
                                      alt="Icon"
                                      height={15}
                                      className="me-1"
                                    />
                                    {packageObject?.vehicles}{" "}
                                    {(packageObject?.vehicles || 0) > 1
                                      ? `${t("tourPage.vehicleLabel_other")}`
                                      : `${t("tourPage.vehicleLabel_one")}`}
                                  </div>
                                </div>
                              </div>
                              <h5>€{packageObject?.price}</h5>
                            </li>
                            {carsExtras.flat().map((extraId) => {
                              const extra = extras.find(
                                (e) => e.id === extraId
                              );
                              return (
                                <li key={Math.random()}>
                                  <div className="">
                                    <h6>{extra?.name}</h6>
                                  </div>
                                  <h5>
                                    + €{extra?.price}{" "}
                                    {extra?.perDay
                                      ? ` * ${tour?.numberOfDays}`
                                      : ""}
                                  </h5>
                                </li>
                              );
                            })}
                            {selectedVoucherPercentage && (
                              <li>
                                <div>
                                  <h6 className="voucher-discount">
                                    {t("checkout.voucherDiscountLabel")}
                                  </h6>
                                </div>
                                <h5 className="voucher-discount">
                                  - €{" "}
                                  {getTourPrice() *
                                    (selectedVoucherPercentage / 100)}
                                </h5>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="booking-sidebar-card">
                      <div className="booking-sidebar-head d-flex justify-content-between align-items-center">
                        <h5>{t("checkout.voucherLabel")}</h5>
                      </div>
                      <div className="booking-sidebar-body">
                        <form>
                          <div className="d-flex align-items-center">
                            <div className="form-custom flex-fill">
                              <input
                                type="text"
                                className="form-control mb-0"
                                placeholder="Voucher code"
                                value={voucherCode}
                                onChange={(e) => {
                                  setVoucherCode(e.target.value);
                                }}
                              />
                            </div>
                            <button
                              className="btn btn-secondary apply-coupon-btn d-flex align-items-center ms-2"
                              onClick={(e) => {
                                e.preventDefault();
                                applyVoucher();
                              }}
                            >
                              {isCheckingVoucher ? "Checking..." : "Apply"}
                              <i className="feather icon-arrow-right ms-2" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="total-rate-card pb-4">
                      <div className="vehicle-total-price">
                        <h5>{t("checkout.totalLabel")}</h5>
                        <span>
                          €{" "}
                          {selectedVoucherPercentage
                            ? `${
                                getTourPrice() -
                                getTourPrice() *
                                  (selectedVoucherPercentage / 100)
                              }`
                            : `${getTourPrice()}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                {activeStep === "add-ons" && (
                  <>
                    {carsExtras.map((carExtra, index) => (
                      <div className="booking-information-main" key={index}>
                        <div className="booking-information-card">
                          <div className="booking-info-head justify-content-between">
                            <div className="d-flex align-items-center">
                              <span>
                                <i className="bx bx-add-to-queue" />
                              </span>
                              <h5>
                                {t("checkout.addonsLabel")}{" "}
                                {carsExtras.length > 1
                                  ? `for Vehicle ${index + 1}`
                                  : ""}
                              </h5>
                            </div>
                          </div>
                          <div className="booking-info-body">
                            <ul className="adons-lists">
                              {extras.map((extra) => {
                                // if startLocation is Training show only extra.name contains Insurance
                                if (
                                  tour?.startLocation === "Training" &&
                                  !extra.name.includes("Insurance")
                                ) {
                                  return null;
                                }

                                if (
                                  (packageObject?.vehicles || 1) < 1 &&
                                  (extra.extraDriver || extra.extraColor)
                                ) {
                                  return null;
                                }

                                return (
                                  <li key={extra.id}>
                                    <div className="adons-types">
                                      <div className="d-flex align-items-center adon-name-info">
                                        <div className="adon-name">
                                          <h6>
                                            {i18n.language === "ro"
                                              ? extra.nameRo
                                              : extra.name}
                                          </h6>
                                        </div>
                                      </div>
                                      {extra.price === 0 ? (
                                        <span className="adon-price">Free</span>
                                      ) : (
                                        <span className="adon-price">
                                          €{extra.price}{" "}
                                          {extra.perDay ? " / day" : ""}
                                        </span>
                                      )}

                                      {carExtra.includes(extra.id || "") ? (
                                        <button
                                          className="btn btn-secondary remove-adon-btn"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (extra.extraColor) {
                                              const localCarColorIds = [
                                                ...carColorIds
                                              ];

                                              localCarColorIds[index] = "";

                                              setCarColorIds(localCarColorIds);
                                            }
                                            const localCarsExtras = [
                                              ...carsExtras
                                            ];

                                            localCarsExtras[index] =
                                              localCarsExtras[index].filter(
                                                (e) => e !== extra.id
                                              );

                                            setCarsExtras(localCarsExtras);

                                            // @ts-ignore
                                            window.dataLayer =
                                              // @ts-ignore
                                              window.dataLayer || [];
                                            // @ts-ignore
                                            window.dataLayer.push({
                                              items: null
                                            });
                                            // @ts-ignore
                                            window.dataLayer.push({
                                              event: "remove_from_cart",
                                              currency: "EUR",
                                              value: extra.price,
                                              items: [
                                                {
                                                  item_id: extra.id,
                                                  item_name: extra.name,
                                                  price: extra.price,
                                                  quantity: 1,
                                                  index: 0,
                                                  item_xsell: tour?.name
                                                }
                                              ]
                                            });
                                          }}
                                        >
                                          <i className="bx bx-minus-circle me-2" />
                                          {t("checkout.removeLabel")}
                                        </button>
                                      ) : (
                                        <button
                                          className="btn add-addon-btn"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (extra.extraColor) {
                                              setActiveCarIndex(index);
                                              handleShow();
                                              return;
                                            }

                                            const localCarsExtras = [
                                              ...carsExtras
                                            ];

                                            localCarsExtras[index] = [
                                              ...localCarsExtras[index],
                                              extra.id || ""
                                            ];

                                            setCarsExtras(localCarsExtras);

                                            // @ts-ignore
                                            window.dataLayer =
                                              // @ts-ignore
                                              window.dataLayer || [];
                                            // @ts-ignore
                                            window.dataLayer.push({
                                              items: null
                                            });
                                            // @ts-ignore
                                            window.dataLayer.push({
                                              event: "add_to_cart",
                                              currency: "EUR",
                                              value: extra.price,
                                              items: [
                                                {
                                                  item_id: extra.id,
                                                  item_name: extra.name,
                                                  price: extra.price,
                                                  quantity: 1,
                                                  index: 0,
                                                  item_xsell: tour?.name
                                                }
                                              ]
                                            });
                                          }}
                                        >
                                          <i className="bx bx-plus-circle me-2" />
                                          {t("checkout.addLabel")}
                                        </button>
                                      )}
                                    </div>
                                    <div className="more-adon-info mt-1">
                                      <p>
                                        {i18n.language === "ro"
                                          ? extra.descriptionRo
                                          : extra.description}
                                      </p>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="booking-information-main">
                      <div className="booking-info-btns d-flex justify-content-end">
                        <button
                          className="btn btn-primary continue-book-btn mb-4"
                          id="continue-book-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setDriversInfo(
                              Array.from({
                                length: packageObject?.rides || 0
                              }).map(() => ({
                                firstName: "",
                                lastName: "",
                                email: "",
                                phone: "",
                                address: "",
                                city: "",
                                country: ""
                              }))
                            );

                            // @ts-ignore
                            window.dataLayer = window.dataLayer || [];
                            // @ts-ignore
                            window.dataLayer.push({ items: null });
                            // @ts-ignore
                            window.dataLayer.push({
                              event: "begin_checkout",
                              currency: "EUR",
                              value: getTourPrice(),
                              coupon: voucherCode ? voucherCode : "",
                              discount: selectedVoucherPercentage
                                ? getTourPrice() *
                                  (selectedVoucherPercentage / 100)
                                : 0,
                              items: [
                                {
                                  item_id: tourId,
                                  item_name: tour?.name,
                                  item_variant: packageName,
                                  price: getTourPrice(),
                                  quantity: 1,
                                  index: 0
                                },
                                ...carsExtras.flat().map((extra) => ({
                                  item_id: extra,
                                  item_name: extras.find((e) => e.id === extra)
                                    ?.name,
                                  price: extras.find((e) => e.id === extra)
                                    ?.price,
                                  quantity: 1,
                                  index: 0
                                }))
                              ]
                            });

                            setActiveStep("details");
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          {t("checkout.continueToDetailsLabel")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="booking-information-main">
                  {activeStep === "details" && (
                    <>
                      {driversInfo.map((driver, index) => (
                        <>
                          <div className="booking-information-card">
                            <div className="booking-info-head">
                              <span>
                                <i className="bx bx-user-pin" />
                              </span>
                              <h5>
                                {t("checkout.driverNDetailsLabel", {
                                  n:
                                    driversInfo.length > 1
                                      ? (index + 1).toString()
                                      : ""
                                })}
                              </h5>
                            </div>
                            <div className="booking-info-body">
                              <div className="booking-timings self-driver-info">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.firstNameLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        value={driver.firstName}
                                        onChange={(e) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].firstName =
                                            e.target.value;
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />
                                      <div
                                        className={`invalid-feedback ${!driver.firstName && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.firstNameErrorLabel")}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.lastNameLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        value={driver.lastName}
                                        onChange={(e) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].lastName =
                                            e.target.value;
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />
                                      <div
                                        className={`invalid-feedback ${!driver.lastName && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.lastNameErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.emailLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={driver.email}
                                        onChange={(e) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].email =
                                            e.target.value;
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />
                                      <div
                                        className={`invalid-feedback ${
                                          (!driver.email ||
                                            !/\S+@\S+\.\S+/.test(
                                              driver.email
                                            )) &&
                                          attemptedSubmit
                                            ? "d-block"
                                            : ""
                                        }`}
                                      >
                                        {t("checkout.emailErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.phoneLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <div className="input-group mb-3">
                                        <PhoneInput
                                          international
                                          defaultCountry="RO"
                                          initialValueFormat="national"
                                          onChange={(value) => {
                                            const newDriversInfo = [
                                              ...driversInfo
                                            ];
                                            newDriversInfo[index].phone =
                                              formatPhoneNumberIntl(
                                                value || ""
                                              );
                                            setDriversInfo(newDriversInfo);
                                          }}
                                        />
                                      </div>

                                      <div
                                        className={`invalid-feedback ${!driver.phone && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.phoneErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.addressLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Address"
                                        value={driver.address}
                                        onChange={(e) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].address =
                                            e.target.value;
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />
                                      <div
                                        className={`invalid-feedback ${!driver.address && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.addressErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.cityLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter City"
                                        value={driver.city}
                                        onChange={(e) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].city =
                                            e.target.value;
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />
                                      <div
                                        className={`invalid-feedback ${!driver.city && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.cityErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-block date-widget">
                                      <label className="form-label">
                                        {t("checkout.countryLabel")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <Select
                                        aria-labelledby="aria-label"
                                        inputId="aria-example-input"
                                        name="aria-live-color"
                                        options={countryPhonePrefixes.map(
                                          (country) => ({
                                            label: country.country,
                                            value: country.country
                                          })
                                        )}
                                        placeholder="Select Country"
                                        onChange={(selectedOption) => {
                                          const newDriversInfo = [
                                            ...driversInfo
                                          ];
                                          newDriversInfo[index].country =
                                            selectedOption?.label || "";
                                          setDriversInfo(newDriversInfo);
                                        }}
                                      />

                                      <div
                                        className={`invalid-feedback ${!driver.country && attemptedSubmit ? "d-block" : ""}`}
                                      >
                                        {t("checkout.countryErrorLabel")}{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                      {(packageObject?.vehicles || 1) < 1 && (
                        <div className="booking-information-card">
                          <div className="booking-info-head">
                            <span>
                              <i className="bx bx-comment" />
                            </span>
                            <h5>{t("checkout.travelCompanionLabel")}</h5> <br />
                          </div>
                          <p className="mb-3">
                            {t("checkout.travelCompanionText")}
                          </p>
                          <div className="col-md-12">
                            <div className="input-block date-widget">
                              <textarea
                                className="form-control"
                                placeholder="Enter Notes"
                                value={travelCompanionNotes}
                                onChange={(e) => {
                                  setTravelCompanionNotes(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="booking-information-card">
                        <div className="booking-info-head">
                          <span>
                            <i className="bx bx-comment" />
                          </span>
                          <h5>{t("checkout.commentsLabel")}</h5> <br />
                        </div>
                        <p className="mb-3">
                          {t("checkout.companyIssuedInvoiceText")}
                        </p>
                        <div className="col-md-12">
                          <div className="input-block date-widget">
                            <textarea
                              className="form-control"
                              placeholder="Enter Comments"
                              value={comments}
                              onChange={(e) => {
                                setComments(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="booking-info-btns d-flex justify-content-end mb-4">
                        <button
                          className="btn btn-secondary"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setDriversInfo([]);
                            setActiveStep("add-ons");
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          {t("checkout.backToAddonsLabel")}
                        </button>
                        <button
                          className="btn btn-primary continue-book-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            setAttemptedSubmit(true);
                            if (
                              driversInfo.some(
                                (driver) =>
                                  !driver.firstName ||
                                  !driver.lastName ||
                                  !driver.email ||
                                  !driver.phone ||
                                  !driver.address ||
                                  !driver.country
                              )
                            ) {
                              window.scrollTo({ top: 0, behavior: "smooth" });
                              return;
                            }

                            // check emails regex
                            const emailReg = /\S+@\S+\.\S+/;
                            const emails = driversInfo.map(
                              (driver) => driver.email
                            );
                            if (emails.some((email) => !emailReg.test(email))) {
                              alert(
                                `${t("checkout.alerts.enterValidEmailAlert")} ${
                                  emails.findIndex(
                                    (email) => !emailReg.test(email)
                                  ) + 1
                                }`
                              );
                              return;
                            }

                            setActiveStep("checkout");

                            // @ts-ignore
                            window.dataLayer = window.dataLayer || [];
                            // @ts-ignore
                            window.dataLayer.push({
                              event: "add_shipping_info"
                            });

                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          {t("checkout.continueToCheckoutLabel")}
                        </button>
                      </div>
                    </>
                  )}

                  {activeStep === "checkout" && (
                    <>
                      {" "}
                      <div className="booking-information-card payment-info-card">
                        <div className="booking-info-head">
                          <div className="d-flex align-items-center">
                            <span>
                              <i className="bx bx-money" />
                            </span>
                            <h5>{t("checkout.paymentLabel")}</h5>{" "}
                          </div>
                        </div>
                        <div className="booking-info-body">
                          <div className="payment-method-types">
                            <ul className="w-25">
                              <li>
                                <label className="payment_custom_check">
                                  <input
                                    type="radio"
                                    name="payment_type"
                                    defaultChecked
                                    disabled
                                  />
                                  <span className="payment_checkmark">
                                    <span className="checked-title">
                                      <ImageWithBasePath
                                        src="assets/img/icons/payment-method-02.svg"
                                        alt="Img"
                                      />
                                    </span>
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          {/* // if there are more than 10 days until the tour date
                          show that he will pay only 20% of the total price now */}
                          {dayjs(selectedDate).diff(dayjs(), "days") > 30 &&
                          tour?.startLocation !== "Training" ? (
                            <div className="payment-terms">
                              <p>{t("checkout.paymentDescriptionText")}</p>
                              <div className="total-rate-card py-4">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                  <h5>
                                    {t("checkout.totalAmountToPayNowLabel")}:
                                  </h5>{" "}
                                  <span className="ms-2">
                                    €
                                    {selectedVoucherPercentage
                                      ? `${
                                          (getTourPrice() -
                                            getTourPrice() *
                                              (selectedVoucherPercentage /
                                                100)) *
                                          0.2
                                        }`
                                      : `${getTourPrice() * 0.2}`}
                                  </span>
                                </div>
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                  <h5>
                                    {t("checkout.totalAmountToPayLaterLabel")}:
                                  </h5>{" "}
                                  <span className="ms-2">
                                    €
                                    {selectedVoucherPercentage
                                      ? `${
                                          (getTourPrice() -
                                            getTourPrice() *
                                              (selectedVoucherPercentage /
                                                100)) *
                                          0.8
                                        }`
                                      : `${getTourPrice() * 0.8}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="total-rate-card py-4">
                              <div className="d-flex flex-row justify-content-start align-items-center">
                                <h5>
                                  {t("checkout.totalAmountToPayNowLabel")}:
                                </h5>{" "}
                                <span className="ms-2">
                                  €
                                  {selectedVoucherPercentage
                                    ? `${
                                        getTourPrice() -
                                        getTourPrice() *
                                          (selectedVoucherPercentage / 100)
                                      }`
                                    : `${getTourPrice()}`}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="col-md-12 pb-3">
                            <div className="input-block m-0">
                              <label className="custom_check d-inline-flex location-check m-0">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: t("checkout.agreeToTermsLabel")
                                  }}
                                ></span>
                                <input
                                  type="checkbox"
                                  name="remeber"
                                  checked={agreeTerms}
                                  onChange={(e) => {
                                    setAgreeTerms(e.target.checked);
                                  }}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="booking-info-btns d-flex justify-content-end mb-4">
                        <button
                          className="btn btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveStep("details");
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          {t("checkout.backToDetailsLabel")}
                        </button>
                        <button
                          className="btn btn-primary continue-book-btn"
                          id="proceed-to-payment"
                          onClick={(e) => {
                            e.preventDefault();
                            if (!agreeTerms) {
                              alert(t("checkout.alerts.agreeToTermsAlert"));
                              return;
                            }

                            // @ts-ignore
                            window.dataLayer = window.dataLayer || [];
                            // @ts-ignore
                            window.dataLayer.push({
                              event: "add_payment_info"
                            });

                            createBooking();
                          }}
                        >
                          {t("checkout.proceedToPaymentLabel")}{" "}
                          {isCreatingBooking && (
                            <Spinner animation="border" size="sm" />
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
